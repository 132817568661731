import React, {  useEffect } from 'react';
// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import Icon1 from "../../assets/images/dahboard-icon-subtract.png"; 
import Icon1Active from "../../assets/images/icon-ticket-active.png"; 
import Icon2 from "../../assets/images/dahboard-icon-card.png"; 
import Icon2Active from "../../assets/images/dahboard-icon-card-active.png"; 
import Icon3 from "../../assets/images/userIcon.png"; 
import Icon3Active from "../../assets/images/userIconActive.png"; 
import Icon4 from "../../assets/images/subscriptions.png"; 
import Icon4Active from "../../assets/images/subscriptionsActive.png"; 
import Icon5 from "../../assets/images/transacetionIcon.png";
import Icon5Active from "../../assets/images/transactionIconActive.png";
import Icon6 from "../../assets/images/referralIcon.png";
import Icon6Active from "../../assets/images/referralIconActive.png";
import settingsIcon from "../../assets/images/settingsIcon.png";
import settingsIconActive from "../../assets/images/settingsIconActive.png";
//i18n
import { withNamespaces } from 'react-i18next';
import {
    getCookie,
    getFirstWord
} from '../../Utils/helper';
import { CardManagementIcon, ReferralsIcon, SettingsIcon, SubscriptionsIcon, TicketsIcon, TransactionsIcon, UserManagementIcon } from '../svg-icons/SideBarIcons';
import styles from './styles/sidebar.module.css';

let userDetails = [];
if(getCookie('userDetails')){
    userDetails = JSON.parse(getCookie('userDetails'));
}
console.log('userDetails', userDetails);

const SidebarContent = (props) => {
    let currentPage = props.location.pathname;
    // Use ComponentDidMount and ComponentDidUpdate method symultaniously
    useEffect(() => {

        var pathName = props.location.pathname;

        const initMenu = () => {
            new MetisMenu("#side-menu");
            var matchingMenuItem = null;
            var ul = document.getElementById("side-menu");
            var items = ul.getElementsByTagName("a");
            for (var i = 0; i < items.length; ++i) {
                if (pathName === items[i].pathname) {
                    matchingMenuItem = items[i];
                    break;
                }
            }
            if (matchingMenuItem) {
                activateParentDropdown(matchingMenuItem);
            }
        }
         initMenu();
      }, [props.location.pathname]);

  
    function  activateParentDropdown(item) {
        item.classList.add("active");
        const parent = item.parentElement;

        if (parent) {
            parent.classList.add("mm-active");
            const parent2 = parent.parentElement;

            if (parent2) {
                parent2.classList.add("mm-show");

                const parent3 = parent2.parentElement;

                if (parent3) {
                    parent3.classList.add("mm-active"); // li
                    parent3.childNodes[0].classList.add("mm-active"); //a
                    const parent4 = parent3.parentElement;
                    if (parent4) {
                        parent4.classList.add("mm-active");
                    }
                }
            }
            return false;
        }
        return false;
    };

    return (
        <React.Fragment>
            <div id="sidebar-menu">
                <ul className="metismenu list-unstyled" id="side-menu">
                    <li className="menu-title">{props.t('Menu') }  </li>
                    <li>
                        {/* <Link to="ticket" className="waves-effect">
                            {
                                currentPage === '/ticket' ||  currentPage === '/ticket-extension'?
                                (<img src={Icon1Active} />) : (<img src={Icon1} />)
                            }
                            <span>Ticket</span>
                        </Link> */}
                        <SideNavLink 
                            isActive={currentPage === '/ticket' ||  currentPage === '/ticket-extension'}
                            to="ticket"
                            label="Ticket"
                            icon={<TicketsIcon />}
                            activeIcon={<TicketsIcon isActive={true}/>}
                        />
                    </li>
                    {userDetails.user_type!= 3 &&
                        <>
                            <li>
                                {/* <Link to="card-management" className=" waves-effect">
                                    {
                                        currentPage === '/card-management'?
                                        (<img src={Icon2Active} />) : (<img src={Icon2} />)
                                    }
                                    <span>Card Management</span>
                                </Link> */}

                                <SideNavLink 
                                    isActive={currentPage === '/card-management'}
                                    to="card-management"
                                    label="Card Management"
                                    icon={<CardManagementIcon />}
                                    activeIcon={<CardManagementIcon isActive={true}/>}
                                />
                            </li>
                            <li>
                                {/* <Link to="transactions" className=" waves-effect">
                                    {
                                        currentPage === '/transactions'?
                                        (<img src={Icon5Active} />) : (<img src={Icon5} />)
                                    }
                                    <span> Transactions</span>
                                </Link> */}

                                <SideNavLink 
                                    isActive={currentPage === '/transactions'}
                                    to="transactions"
                                    label="Transactions"
                                    icon={<TransactionsIcon />}
                                    activeIcon={<TransactionsIcon isActive={true}/>} 
                                />
                            </li>
                            <li>
                                {/* <Link to="user-management" className=" waves-effect">
                                    {
                                        currentPage === '/user-management'?
                                        (<img src={Icon3Active} />) : (<img src={Icon3} />)
                                    }
                                    <span>User Management</span>
                                </Link> */}

                                <SideNavLink 
                                    isActive={currentPage === '/user-management'}
                                    to="user-management"
                                    label="User Management"
                                    icon={<UserManagementIcon />}
                                    activeIcon={<UserManagementIcon isActive={true}/>} 
                                />
                            </li>

                            <li>
                                {/* <Link to="subscriptions" className=" waves-effect">
                                    {
                                        currentPage === '/subscriptions'?
                                        (<img src={Icon4Active} />) : (<img src={Icon4} />)
                                    }
                                    <span>Subscriptions</span>
                                </Link> */}

                                
                                <SideNavLink 
                                    isActive={currentPage === '/subscriptions'}
                                    to="subscriptions"
                                    label="Subscriptions"
                                    icon={<SubscriptionsIcon />}
                                    activeIcon={<SubscriptionsIcon isActive={true}/>} 
                                />
                            </li>
                        </>
                    }

                    {userDetails.user_type == 1 &&
                        <li>
                            {/* <Link to="referrals" className=" waves-effect">
                                {
                                    currentPage === '/referrals'?
                                        (<img src={Icon6Active} />) : (<img src={Icon6} />)
                                }
                                <span>Referrals</span>
                            </Link> */}
                            <SideNavLink 
                                isActive={currentPage === '/referrals'}
                                to="referrals"
                                label="Referrals"
                                icon={<ReferralsIcon />}
                                activeIcon={<ReferralsIcon isActive={true}/>} 
                            />
                        </li>
                    }
                    <li>
                        {/* <Link to="settings" className=" waves-effect"  >
                            {
                                currentPage === '/settings'?
                                (<img src={settingsIconActive} />) : (<img src={settingsIcon} />)
                            }
                            <span>Settings</span>
                        </Link> */}

                        <SideNavLink 
                            isActive={currentPage === '/settings'}
                            to="settings"
                            label="Settings"
                            icon={<SettingsIcon />}
                            activeIcon={<SettingsIcon isActive={true}/>} 
                        />
                    </li>
                </ul>
            </div>
        </React.Fragment>
    );
}

/**
 * 
 * @param {{
 *  icon: import('react').ReactNode,
 *  activeIcon: import('react').ReactNode,
 *  isActive: boolean,
 *  label: import('react').ReactNode,
 *  to: string,
 * }} param0 
 * @returns 
 */
const SideNavLink = ({ icon, activeIcon, isActive, label, to }) => {
    return (
        <Link to={to} className={["waves-effect", styles.sideNavItem].join(" ")} >
            <div 
                className={styles.icon + (isActive ? ` ${styles.iconActive}` : '') }
            >
                {isActive ? (activeIcon || icon) :  icon}
            </div>
            <span>{label}</span>
        </Link>
    );
}


export default withRouter(withNamespaces()(SidebarContent));
