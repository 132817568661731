import React from "react";
import { Redirect } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Pages Calendar
import Calendar from "../pages/Calendar/index";


// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgotPwd from "../pages/Authentication/ForgotPassword";








//Pages

import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";

import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";



// My Pages
import Ticket from "../pages/Ticket";
import TicketExtension from "../pages/Ticket/Ticketextension";
import cardManagement from "../pages/Card-management";
import Transactions from "../pages/Transactions";
import Users from "../pages/Users";
import Subscriptions from "../pages/Subscriptions";
import CompleteAccount from "../pages/Authentication/CompleteAccount";
import InviteLink from "../pages/Authentication/InviteLink";
import GoAhead from "../pages/go-ahead";
import GoLive from "../pages/go-live";
import NoThankyou from "../pages/no-thankyou";
import JobComplete from "../pages/job-complete";
import Referrals from "../pages/Referrals";
import Settings from "../pages/Settings";

const userRoutes = [

	{ path: "/ticket", component: Ticket },
	{ path: "/card-management", component: cardManagement },
	{ path: "/transactions", component: Transactions },
	{ path: "/user-management", component: Users },
	{ path: "/subscriptions", component: Subscriptions },
	{ path: "/invite-link", component: InviteLink },
	{ path: "/go-ahead", component: GoAhead },
	{ path: "/go-live", component: GoLive },
	{ path: "/no-thank-you", component: NoThankyou },
	{ path: "/job-complete", component: JobComplete },
	{ path: "/referrals", component: Referrals },
	{ path: "/settings", component: Settings },
	

	// //calendar
	{ path: "/calendar", component: Calendar },

	// //profile
	{ path: "/profile", component: UserProfile },
	
	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/ticket" /> }
];

const authRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgotPwd },
	{ path: "/register", component: Register },

	{ path: "/pages-maintenance", component: PagesMaintenance },
	{ path: "/pages-comingsoon", component: PagesComingsoon },
	{ path: "/pages-404", component: Pages404 },
	{ path: "/pages-500", component: Pages500 },


	{ path: "/complete-account", component: CompleteAccount },
	{ path: "/go-ahead", component: GoAhead },
	{ path: "/go-live", component: GoLive },
	{ path: "/no-thank-you", component: NoThankyou },
	{ path: "/job-complete", component: JobComplete },
	{ path: "/invite-link", component: InviteLink },
	
];

const globalRoutes = [
	{ path: "/ticket-extension", component: TicketExtension },
];	

export { userRoutes, authRoutes, globalRoutes };
