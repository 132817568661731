import React from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import logo from "../../assets/images/logo.svg";
import logoLightPng from "../../assets/images/logo-white.png";
import logoLightSvg from "../../assets/images/logo-white.svg";
import logoDark from "../../assets/images/logo-dark.png";
import iconLogout from "../../assets/images/iconLogout.png";

import { withNamespaces } from 'react-i18next';
import { showRightSidebarAction, toggleLeftmenu, changeSidebarType } from "../../store/actions";
import CreditBalance from '../Common/CreditBalance';
import { getCookie } from '../../Utils/helper';

let userDetails = getCookie("userDetails");
let loginUser = {
    id: '',
    firstname : '',
    surname: '',

};
if (userDetails) {
    loginUser = JSON.parse(userDetails);
}
const Header = (props) => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    function tToggle() {
        props.toggleLeftmenu(!props.leftMenu);
        if (props.leftSideBarType === "default") {
            props.changeSidebarType("condensed", isMobile);
        } else if (props.leftSideBarType === "condensed") {
            props.changeSidebarType("default", isMobile);
        }
    }

    return (
        <React.Fragment>
            <header id="page-topbar">
                {/* <Pace color="#27ae60"/> */}
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box">
                            <Link to="/" className="logo logo-dark">
                                <span className="logo-sm">
                                    <img src={logo} alt="" height="22" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logoDark} alt="" height="17" />
                                </span>
                            </Link>

                            <Link to="/" className="logo logo-light">
                                <span className="logo-sm">
                                    <img src={logoLightSvg} alt="" height="40" />
                                </span>
                                <span className="logo-lg">
                                    <img src={logoLightPng} alt="" height="19" />
                                </span>
                            </Link>
                        </div>
                        <button type="button" onClick={() => { tToggle() }} className="btn btn-sm px-3 font-size-16 header-item waves-effect" id="vertical-menu-btn">
                            <i className="fa fa-fw fa-bars"></i>
                        </button>
                        <CreditBalance />
                    </div>

                    <div className="d-flex">
                        <h3 className="header-title">{loginUser.company.name}</h3>
                    </div>

                    <div className="d-flex">
                        <div className="Aligner profile-name ">
                            <div className="Aligner-item">
                                {loginUser.firstname || ''} {loginUser.surname || ''}
                                <div className="text-right"><small>Contact ID: {loginUser.id || ''}</small></div>
                            </div>
                        </div>
                        <ProfileMenu />
                        <div className="top-line-spacer" ></div>
                        <div className="logout-button header-item ">
                            <Link  to="/logout" className="btn noti-icon  waves-effect">
                                <img src={iconLogout} />
                            </Link>
                        </div>
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
}
const mapStatetoProps = state => {
    const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
    return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, { showRightSidebarAction, toggleLeftmenu, changeSidebarType })(withNamespaces()(Header));
